import { HttpErrorResponse } from '@angular/common/http';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { PageTypeEnum } from '@portal-app/enums/page-type.enum';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import { IBookLibraryFilters } from '@portal-app/interfaces/book/project/book-library.interface';
import {
  ICourseLibraryFilters,
  ISearchAutoComplete,
} from '@portal-app/interfaces/course/project/course-library.interface';
import { ICourseTile } from '@portal-app/interfaces/course/project/course.interface';
import { IPsLibraryFilters } from '@portal-app/interfaces/ps/project/ps-library.interface';
import { BookLibraryMapper } from '@portal-app/mapper/book/book-library.mapper';
import { CourseLibraryMapper } from '@portal-app/mapper/course/course-library.mapper';
import { BookLibraryService } from '@portal-app/services/book/book-library.service';
import { CourseLibraryService } from '@portal-app/services/course/course-library.service';
import { PSLibraryService } from '@portal-app/services/ps/ps-library.service';

import { GoogleTagManagerService } from '@lru/felib';
import { ISliderSettings } from '@portal-app/modules/shared/slider/interfaces/slider-settings.interface';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime } from 'rxjs/operators';
import { ProjectTypeEnum } from '../../../../../enums/project-type.enum';
import { AppInitService } from '../../../../../services/shared/app-init.service';
import { DropdownService } from '../../../../../services/shared/dropdown.service';

@Component({
  selector: 'portal-library-search[type][libraryUrl]',
  templateUrl: './library-search.component.html',
  styleUrls: ['./library-search.component.scss'],
})
export class LibrarySearchComponent implements OnInit, OnDestroy {
  @Input() type!: string;
  @Input() libraryUrl?: string;

  focus = false;
  enterActivated = false;
  filters?: ICourseLibraryFilters | IBookLibraryFilters | IPsLibraryFilters;
  filterTextChanged = new Subject<string>();
  autocomplete?: ISearchAutoComplete;
  activeQuery = '';
  currentQuery = '';
  bookSliderSettings: ISliderSettings = {
    Type: SectionTypeEnum.BookList,
    PreviewTile: true,
    MaxSlidesPerView: 5,
  };
  autoCompleteSubscription?: Subscription;
  filtersSubscription?: Subscription;
  theme = this.appInitService.isPs ? 'dark' : 'light';

  constructor(
    private courseLibraryService: CourseLibraryService,
    private bookLibraryService: BookLibraryService,
    private psLibraryService: PSLibraryService,
    private courseLibraryMapper: CourseLibraryMapper,
    private bookLibraryMapper: BookLibraryMapper,
    private router: Router,
    private dropdownService: DropdownService,
    private appInitService: AppInitService,
    private gtmService: GoogleTagManagerService
  ) {}

  ngOnDestroy() {
    this.filtersSubscription?.unsubscribe();
    this.autoCompleteSubscription?.unsubscribe();
  }

  ngOnInit() {
    switch (this.type) {
      case PageTypeEnum.BookLibrary: {
        this.filtersSubscription = this.bookLibraryService.data$.subscribe((data) => {
          this.filters = data;
          this.currentQuery = this.filters.Query;
        });
        break;
      }
      case PageTypeEnum.CourseLibrary: {
        this.filtersSubscription = this.courseLibraryService.data$.subscribe((data) => {
          this.filters = data;
          this.currentQuery = this.filters.Query;
        });
        break;
      }
      case PageTypeEnum.PsAssignmentLibrary: {
        this.filtersSubscription = this.psLibraryService.data$.subscribe((data) => {
          this.filters = data;
          this.currentQuery = this.filters.Query;
        });
        break;
      }
    }

    this.filterTextChanged.pipe(debounceTime(500)).subscribe((filterQuery) => {
      if (this.enterActivated) {
        this.enterActivated = false;
        this.autocomplete = undefined;
        return;
      }
      if (filterQuery && filterQuery.length >= 2) {
        if (this.autoCompleteSubscription) {
          this.autoCompleteSubscription.unsubscribe();
        }
        if (this.type === PageTypeEnum.BookLibrary) {
          this.autoCompleteSubscription = this.bookLibraryService.postAzureAutoComplete(filterQuery).subscribe(
            (autocomplete) => {
              this.autocomplete = this.bookLibraryMapper.mapAutoCompleteFromApi(filterQuery, autocomplete);
              if (this.enterActivated) {
                this.enterActivated = false;
                this.autocomplete = undefined;
                return;
              }
              if (
                this.autocomplete.Suggestions.length ||
                this.autocomplete.Courses.length ||
                this.autocomplete.Books.length ||
                this.autocomplete.BookCollections.length
              ) {
                this.focus = true;
              } else {
                this.focus = false;
              }
              this.enterActivated = false;
            },
            (error: HttpErrorResponse) => {
              // hide suggestions
              this.autocomplete = undefined;
              this.focus = false;
              this.enterActivated = false;
            }
          );
        } else if (this.type === PageTypeEnum.CourseLibrary) {
          this.autoCompleteSubscription = this.courseLibraryService
            .postAzureAutoComplete(filterQuery)
            .pipe(
              catchError(() => {
                // hide suggestions
                this.autocomplete = undefined;
                return EMPTY;
              })
            )
            .subscribe((autocomplete) => {
              this.autocomplete = this.courseLibraryMapper.mapAutoCompleteFromApi(filterQuery, autocomplete);
              if (this.enterActivated) {
                this.enterActivated = false;
                this.autocomplete = undefined;
                return;
              }
              if (
                this.autocomplete.Suggestions.length ||
                this.autocomplete.Courses.length ||
                this.autocomplete.Books.length ||
                this.autocomplete.BookCollections.length
              ) {
                this.focus = true;
              } else {
                this.focus = false;
              }
            });
        }
      } else {
        // hide suggestions
        this.autocomplete = undefined;
      }
    });
  }

  async onKeyup(query: string, event: KeyboardEvent) {
    let trimmedQuery = query.trim();
    if (event.keyCode === 13) {
      this.focus = false;
      this.enterActivated = true;
      if (this.filters) {
        this.filters.Query = trimmedQuery;
      }
      await this.query();
    } else if (this.activeQuery !== trimmedQuery) {
      this.activeQuery = trimmedQuery;
      this.filterTextChanged.next(this.activeQuery);
    }
  }

  onFocus() {
    if (
      this.autocomplete?.Suggestions.length ||
      this.autocomplete?.Courses.length ||
      this.autocomplete?.Books.length ||
      this.autocomplete?.BookCollections.length
    ) {
      this.focus = true;
    }
  }

  onClickAutoComplete(item: ICourseTile) {
    this.gtmService.pushTagWithUserType(GTMEvent.Library_search_autocomplete, { title: item.Title });
  }

  clearQuery() {
    this.currentQuery = '';
    this.activeQuery = '';
    if (this.type === PageTypeEnum.BookLibrary) {
      this.bookLibraryService.clearQuery();
    } else if (this.type === PageTypeEnum.CourseLibrary) {
      this.courseLibraryService.clearQuery();
    }
    this.autocomplete = undefined;
  }

  async changeQuery(query: string) {
    this.currentQuery = query;
    await this.query();
  }

  async query() {
    if (this.filters) {
      this.filters.Query = this.currentQuery;

      if (this.libraryUrl === window.location.pathname || !this.libraryUrl) {
        this.focus = false;
        if (this.type === PageTypeEnum.BookLibrary) {
          this.bookLibraryService.query();
        } else if (this.type === PageTypeEnum.CourseLibrary) {
          this.courseLibraryService.query();
        } else if (this.type === PageTypeEnum.PsArticleLibrary || this.type === PageTypeEnum.PsAssignmentLibrary) {
          await this.psLibraryService.search(this.filters.Query);
        }
      } else {
        this.dropdownService.closeDropdown();

        await this.router.navigate([this.libraryUrl]);

        this.focus = false;
        if (this.type === PageTypeEnum.BookLibrary) {
          this.bookLibraryService.query();
        } else if (this.type === PageTypeEnum.CourseLibrary) {
          this.courseLibraryService.query();
        } else if (
          this.filters &&
          (this.type === PageTypeEnum.PsArticleLibrary || this.type === PageTypeEnum.PsAssignmentLibrary)
        ) {
          await this.psLibraryService.search(this.filters.Query);
        }
      }
    }
  }

  @HostListener('document:click', ['$event.target'])
  documentClick(target: HTMLElement) {
    if (!target.closest('portal-library-search')) {
      this.focus = false;
    }
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyup(e: KeyboardEvent) {
    const target = e.target as HTMLElement;
    if (e.which === 9 && !target.closest('portal-library-search')) {
      this.focus = false;
    }
  }

  get PageTypeEnum() {
    return PageTypeEnum;
  }

  get ProjectTypeEnum(): typeof ProjectTypeEnum {
    return ProjectTypeEnum;
  }
}
