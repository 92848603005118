<div [attr.data-theme]="theme">
  <form *ngIf="filters" (ngSubmit)="query()" #heroForm="ngForm">
    <i *ngIf="theme === 'light'" class="icon icon-16" [inlineSVG]="'search.svg'"></i>
    <input
      class="query toggle-dropdown"
      name="query"
      [(ngModel)]="currentQuery"
      type="text"
      [placeholder]="
        theme === 'light'
          ? ('LibrarySearch.SearchPlaceholderLight' | translate)
          : ('LibrarySearch.SearchPlaceholder' | translate)
      "
      (keyup)="onKeyup(currentQuery, $event)"
      (focus)="onFocus()"
      autocomplete="off"
      tabindex="0"
      autofocus
      maxlength="100"
    />
    <button *ngIf="theme === 'light'" type="submit" class="search btn secondary">
      {{ 'LibrarySearch.Search' | translate }}
    </button>
    <button *ngIf="theme === 'dark'" type="submit" class="btn square transparent search">
      <i class="icon icon-16" [inlineSVG]="'search.svg'"></i>
    </button>
    <button class="btn square transparent reset" *ngIf="filters.Query" (click)="clearQuery()">
      <i class="icon icon-20" [inlineSVG]="'x-mark-filled.svg'"></i>
    </button>
  </form>
  <div [class.hidden]="!focus">
    <div *ngIf="autocomplete" class="dropdown-content default-dropdown-theme">
      <ng-container *ngIf="autocomplete.Suggestions.length">
        <label class="sub-item color-grey-50">{{ 'LibrarySearch.DidYouMean' | translate }}</label>
        <div class="ws-10"></div>
        <ul class="suggestions-list">
          <li *ngFor="let item of autocomplete.Suggestions" class="sub-item" (click)="changeQuery(item)" tabindex="0">
            {{ item }}
          </li>
        </ul>
      </ng-container>
      <ng-container *ngIf="autocomplete.Courses.length">
        <ng-container *ngIf="autocomplete.Suggestions.length">
          <div class="ws-20"></div>
        </ng-container>
        <label class="sub-item color-grey-50">{{ 'LibrarySearch.GoDirectlyToCourse' | translate }}</label>
        <ng-container *ngIf="autocomplete.Courses.length">
          <div class="ws-10"></div>
          <ul class="courses-list">
            <li *ngFor="let item of autocomplete.Courses" class="hr-between">
              <a
                href="{{ item.Url }}"
                (click)="onClickAutoComplete(item)"
                target="_blank"
                rel="noopener"
                class="move-left-on-hover"
                tabindex="0"
              >
                <portal-course-card-preview [item]="item"></portal-course-card-preview>
              </a>
            </li>
          </ul>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="autocomplete.Books.length">
        <ng-container *ngIf="autocomplete.Suggestions.length">
          <div class="ws-20"></div>
        </ng-container>
        <label class="sub-item color-grey-50">{{ 'LibrarySearch.GoDirectlyToBook' | translate }}</label>
        <ng-container *ngIf="autocomplete.Books.length">
          <div class="ws-10"></div>
          <ul class="courses-list">
            <li *ngFor="let item of autocomplete.Books" class="hr-between">
              <a
                [routerLink]="'/' + PageTypeEnum.BookInfo + '/' + item.Slug"
                target="_blank"
                rel="noopener"
                class="move-left-on-hover"
                tabindex="0"
              >
                <portal-book-card-preview [item]="item" [settings]="bookSliderSettings"></portal-book-card-preview>
              </a>
            </li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
