import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { GoogleTagManagerService, UserService } from '@lru/felib';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { PageService } from '@portal-app/services/shared/page/page.service';
import { firstValueFrom } from 'rxjs';
import { PageTypeEnum } from '../enums/page-type.enum';
import { AppInitService } from '../services/shared/app-init.service';
import { NavbarService } from '../services/shared/navbar.service';
import { SeoService } from '../services/shared/seo.service';

@Injectable({
  providedIn: 'root',
})
export class RouteHasPage implements CanActivate {
  constructor(
    private pageService: PageService,
    private seoService: SeoService,
    private userService: UserService,
    private navbarService: NavbarService,
    private router: Router,
    private appInitService: AppInitService,
    private googleTagManagerService: GoogleTagManagerService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const pageSettings = await firstValueFrom(this.pageService.pageSettings$);

    if (pageSettings) {
      const metaData = await firstValueFrom(this.seoService.getMetaData(pageSettings.pageId));
      this.seoService.setMetaData(metaData);

      if (
        this.appInitService.shouldUseTagManager &&
        this.appInitService.siteConfiguration.GoogleTagManagerId &&
        pageSettings.pageUrl
      ) {
        this.googleTagManagerService.setId(this.appInitService.siteConfiguration.GoogleTagManagerId);

        const gtmTag = {
          event: GTMEvent.Page,
          pageName: decodeURI(pageSettings.pageUrl),
          visitorType: this.userService.userType,
        };
        this.googleTagManagerService.pushTag(gtmTag);
      }

      return true;
    } else {
      // this is to redirect old urls to new urls
      if (route.data.pageType === PageTypeEnum.Error503) {
        return true;
      } else if (
        route.data.pageType === PageTypeEnum.BookLibrary ||
        route.data.pageType === PageTypeEnum.CourseLibrary ||
        route.data.pageType === PageTypeEnum.PsAssignmentLibrary
      ) {
        const libraryUrl = await firstValueFrom(this.navbarService.libraryUrl$);
        this.router.navigate([libraryUrl], { queryParams: route.queryParams });
      } else if (route.data.pageType === PageTypeEnum.CourseStudentAnswers) {
        if (window.location.pathname.replace('/', '') === PageTypeEnum.CourseStudentAnswers) {
          const courseStudentAnswersUrl = await firstValueFrom(this.navbarService.courseStudentAnswersUrl$);
          this.router.navigate([courseStudentAnswersUrl], { queryParams: route.queryParams });
        } else {
          return true;
        }
      } else if (
        route.data.pageType === PageTypeEnum.CourseFavorites ||
        route.data.pageType === PageTypeEnum.BookFavorites
      ) {
        const favoritesUrl = await firstValueFrom(this.navbarService.favoritesUrl$);
        this.router.navigate([favoritesUrl], { queryParams: route.queryParams });
      } else if (route.data.pageType === PageTypeEnum.CourseAnnualPlan) {
        const courseAnnualPlanUrl = await firstValueFrom(this.navbarService.courseAnnualPlanUrl$);
        this.router.navigate([courseAnnualPlanUrl], { queryParams: route.queryParams });
      } else {
        this.pageService.showError(window.location.pathname, 404);
      }
      return false;
    }
  }
}
