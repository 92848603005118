import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { ApiService, CssColorService, GoogleTagManagerService, UserService } from '@lru/felib';
import { IIsbn } from '@lru/felib/lib/services/core/user/interfaces/isbn.interface';
import { TranslateService } from '@ngx-translate/core';
import { ProjectTypeEnum } from '@portal-app/enums/project-type.enum';
import { ISiteConfiguration } from '@portal-app/interfaces/project/site-configuration.interface';
import { UmbLanguageType } from '@portal-app/interfaces/umbraco/umb-language.type';
import { IUmbSiteConfiguration } from '@portal-app/interfaces/umbraco/umb-site-configuration.interface';
import { SiteConfigurationMapper } from '@portal-app/mapper/shared/site-configuration.mapper';
import { environment } from '@portal-env/environment';
import device from 'current-device';
import { firstValueFrom, iif, of } from 'rxjs';
import { map, retry, switchMap, tap } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { SeoService } from './seo.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  siteConfiguration = this.setDefaultSiteConfiguration();

  constructor(
    private translateService: TranslateService,
    private apiService: ApiService,
    private siteConfigurationMapper: SiteConfigurationMapper,
    private userService: UserService,
    private httpClient: HttpClient,
    private helperService: HelperService,
    private googleTagManagerService: GoogleTagManagerService,
    private cssColorService: CssColorService,
    private injector: Injector,
    @Inject(DOCUMENT) private document: Document
  ) {}

  public setDefaultSiteConfiguration(): ISiteConfiguration {
    return {
      ISBNs: [],
      PrimaryColor: '#ff0000',
      PrimaryTextColor: '#ffffff',
      SecondaryColor: '#00ff00',
      SecondaryTextColor: '#ffffff',
      SiteBackgroundColor: '#ffffff',
      SiteLinkColor: '#000000',
      SiteTextColor: '#1b1b1b',
      ErrorPages: [],
      language: 'da',
    };
  }

  async Init() {
    window.bk = window.bk || new Date().getTime();
    window.useWebP = this.canUseWebP();
    window.scrollLocked = false;
    window.scrollbarWidth = this.getScrollbarWidth();
    window.error = window.error;
    window.device = device;

    localStorage.removeItem('lastUpdated');
    await this.startup();
  }

  setLang(lang: UmbLanguageType, projectType?: ProjectTypeEnum) {
    /** Sets the lang attribute of the html tag in the root index.html */
    this.document.documentElement.setAttribute('lang', lang);

    const languagesToAdd = [`base/${lang}`];
    let useLang$ = this.translateService.use(`base/${lang}`);

    switch (projectType) {
      case ProjectTypeEnum.SuperReader:
      case ProjectTypeEnum.Superbog:
        languagesToAdd.push(`superreader/${lang}`);
        useLang$ = this.translateService.use(`superreader/${lang}`);
        break;
    }

    this.translateService.addLangs(languagesToAdd);
    this.translateService.setDefaultLang(`base/${lang}`);

    return useLang$;
  }

  private async startup() {
    return firstValueFrom(
      this.apiService
        .getUmbraco<IUmbSiteConfiguration>(`alineaportal/getportal/${this.hostName}/${this.userService.userType}`)
        .pipe(
          tap((config) => (this.siteConfiguration = this.siteConfigurationMapper.mapSiteConfigurationFromApi(config))),
          map((config) => this.siteConfigurationMapper.mapSiteConfigurationFromApi(config)),
          tap(() => {
            if (this.siteConfiguration.ProjectType && this.siteConfiguration.Title) {
              this.updateSiteManifest(this.siteConfiguration.ProjectType, this.siteConfiguration.Title);

              if (this.siteConfiguration.ProjectType) {
                this.setStylesheet(this.siteConfiguration.ProjectType);
              }

              if (this.siteConfiguration.ProjectType) {
                this.setTheme(this.siteConfiguration.ProjectType);
              }

              this.addGoogleTagManager();

              /** Using injector to not run into circular dependency - SeoService requires AppInitService */
              const seoService = this.injector.get(SeoService);
              seoService.setGoogleVerificationId(this.siteConfiguration.GoogleVerificationId);
            }
          }),
          switchMap(() =>
            this.setLang(
              this.siteConfiguration.language ?? environment.fallbackLanguage,
              this.siteConfiguration.ProjectType
            )
          ),
          switchMap(() => iif(() => !!this.siteConfiguration.ProjectGroup, this.gethHostUrl(), of(undefined))),
          retry(2),
          tap((basePath) => {
            if (this.siteConfiguration && basePath) {
              this.siteConfiguration.BasePath = basePath;
            }
            if (window.error?.Message) {
              throw window.error;
            }
          })
        )
    );
  }

  private gethHostUrl() {
    return this.apiService.getNext<string>(`projectgroup/gethosturl/${this.siteConfiguration.ProjectGroup}`);
  }

  private addGoogleTagManager() {
    if (this.shouldUseTagManager && this.siteConfiguration?.GoogleTagManagerId) {
      this.googleTagManagerService.setId(this.siteConfiguration.GoogleTagManagerId);
      this.googleTagManagerService.addToDOM();
    }
  }

  /**
   * This will update the manifest.json
   * @param projectType - project category name (see ProjectTypeEnum)
   * @param  name - project name
   */
  private async updateSiteManifest(projectType: ProjectTypeEnum, name: string) {
    const manifestName = this.isFagportal === true ? projectType + ' - ' + name : projectType;

    await firstValueFrom(
      this.httpClient.post<void>(environment.internalBackendUrl() + 'Home/UpdateSiteManifest', {
        withCredentials: true,
        productName: manifestName,
      })
    );
  }

  private setTheme(projectType: ProjectTypeEnum) {
    if (projectType === ProjectTypeEnum.PS) {
      document.documentElement.classList.add('theme-dark');
    } else {
      document.documentElement.classList.add('theme-light');
    }
  }

  public setStylesheet(projectType: ProjectTypeEnum) {
    const head = document.head;
    const link = document.createElement('link');

    link.type = 'text/css';
    link.rel = 'stylesheet';

    // This is to include local style sheet for the specified project type
    let href = '';
    switch (projectType) {
      case ProjectTypeEnum.Fagportal:
        href = 'fag';
        break;
      case ProjectTypeEnum.SuperReader:
      case ProjectTypeEnum.Superbog:
        href = 'sup';
        this.cssColorService.setCssColors({
          prefix: 'felib',
          items: [
            {
              name: 'primary-teacher',
              hex100: this.siteConfiguration.PrimaryColor,
              add100Suffix: true,
              variants: [80, 50, 20, 10],
            },
            {
              name: 'primary-student',
              hex100: this.siteConfiguration.PrimaryColor,
              add100Suffix: true,
              variants: [80, 50, 20, 10],
            },
            {
              name: 'success',
              hex100: '#6dddb2',
            },
            {
              name: 'error',
              hex100: '#e8505c',
            },
            {
              name: 'grey',
              hex100: '#071839',
              add100Suffix: true,
              variants: [90, 80, 70, 60, 50, 40, 30, 20, 10],
            },
          ],
        });
        break;
      case ProjectTypeEnum.PS:
        href = 'ps';
        break;
      default:
        href = 'fag';
    }

    link.href = `${this.helperService.isLocalhost() ? '' : '/bundles'}/${href}.min.css?bk=${window.bk}`;

    head.appendChild(link);

    if (this.siteConfiguration) {
      const siteBackgroundColor = this.helperService.hexToRgb(this.siteConfiguration.SiteBackgroundColor);
      const siteTextColor = this.helperService.hexToRgb(this.siteConfiguration.SiteTextColor);
      const siteLinkColor = this.helperService.hexToRgb(this.siteConfiguration.SiteLinkColor);
      const primaryColor = this.helperService.hexToRgb(this.siteConfiguration.PrimaryColor);
      const primaryTextColor = this.helperService.hexToRgb(this.siteConfiguration.PrimaryTextColor);
      const secondaryColor = this.helperService.hexToRgb(this.siteConfiguration.SecondaryColor);
      const secondaryTextColor = this.helperService.hexToRgb(this.siteConfiguration.SecondaryTextColor);

      if (siteBackgroundColor) {
        document.documentElement.style.setProperty('--color-background', siteBackgroundColor);
      }
      if (siteTextColor) {
        document.documentElement.style.setProperty('--color-text', siteTextColor);
      }
      if (siteLinkColor) {
        document.documentElement.style.setProperty('--color-link', siteLinkColor);
      }
      if (primaryColor) {
        document.documentElement.style.setProperty('--color-primary-background', primaryColor);
      }
      if (primaryTextColor) {
        document.documentElement.style.setProperty('--color-primary-text', primaryTextColor);
      }
      if (secondaryColor) {
        document.documentElement.style.setProperty('--color-secondary-background', secondaryColor);
      }
      if (secondaryTextColor) {
        document.documentElement.style.setProperty('--color-secondary-text', secondaryTextColor);
      }
    }
  }

  private get hostName() {
    return this.helperService.isLocalhost() ? environment.localHostName : window.location.hostname;
  }

  getErrorPage(statusCode: string): number | undefined {
    return this.siteConfiguration.ErrorPages.find((item) => item.StatusCode === statusCode)?.Id;
  }

  private getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement('div');
    outer.style.visibility = 'hidden';
    outer.style.overflow = 'scroll'; // forcing scrollbar to appear
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement('div');
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode?.removeChild(outer);
    return scrollbarWidth;
  }

  private canUseWebP() {
    const elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
  }

  public setISBNs(isbns: string[]) {
    const body = {
      ISBNs: isbns,
    };

    this.apiService.postInternalBackend<IIsbn[]>('ISBN/Check/', body).subscribe((isbns: IIsbn[]) => {
      if (isbns?.length) {
        this.userService.setISBNs(isbns);
      }
    });
  }

  get isPs() {
    return this.siteConfiguration.ProjectType === ProjectTypeEnum.PS;
  }
  get isSuperbog() {
    return this.siteConfiguration.ProjectType === ProjectTypeEnum.Superbog;
  }
  get isSuperreader() {
    return this.siteConfiguration.ProjectType === ProjectTypeEnum.SuperReader;
  }
  get isFagportal() {
    return this.siteConfiguration.ProjectType === ProjectTypeEnum.Fagportal;
  }

  get isForstaa() {
    // Forstå's id from Umbraco is 17193
    return this.siteConfiguration.ProjectType === ProjectTypeEnum.Fagportal && this.siteConfiguration.Id === '17193';
  }

  get shouldUseTagManager() {
    if (location.hostname !== 'localhost' && this.siteConfiguration.GoogleTagManagerId) {
      return true;
    }
    return false;
  }

  setHeaderHeight(height: number) {
    this.siteConfiguration.HeaderHeight = height;
  }
}
