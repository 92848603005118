import { Location, PlatformLocation } from '@angular/common';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, GoogleTagManagerService, UserService } from '@lru/felib';
import { TranslateService } from '@ngx-translate/core';
import { QueryStrings } from '@portal-app/constants/query-strings.const';
import { ContentTypeEnum } from '@portal-app/enums/content-type.enum';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { HelperService } from '@portal-app/services/shared/helper.service';
import { EMPTY, ReplaySubject, catchError, firstValueFrom, tap } from 'rxjs';
import { PageTypeEnum } from '../../enums/page-type.enum';
import { PsLibraryFilterEnum } from '../../enums/ps/ps-library-filter.enum';
import { ILibraryTag } from '../../interfaces/course/project/course-library.interface';
import { IPsAssignment } from '../../interfaces/ps/project/ps-assignment.interface';
import { IPsLibraryFilters } from '../../interfaces/ps/project/ps-library.interface';
import { IUmbPsLibrary } from '../../interfaces/ps/umbraco/umb-ps-library.interface';
import { PsLibraryMapper } from '../../mapper/ps/ps-library.mapper';
import { AppInitService } from '../shared/app-init.service';
import { NavbarService } from '../shared/navbar.service';
import { PageService } from '../shared/page/page.service';

@Injectable({
  providedIn: 'root',
})
export class PSLibraryService {
  filters?: IPsLibraryFilters;

  private data = new ReplaySubject<IPsLibraryFilters>(1);
  data$ = this.data.asObservable();

  constructor(
    private helperService: HelperService,
    private platformLocation: PlatformLocation,
    private readonly location: Location,
    private psLibraryMapper: PsLibraryMapper,
    private apiService: ApiService,
    private pageService: PageService,
    private translate: TranslateService,
    private userService: UserService,
    private router: Router,
    private navbarService: NavbarService,
    private gtmService: GoogleTagManagerService,
    private appInitService: AppInitService
  ) {
    this.platformLocation.onPopState(() => {
      this.setFiltersToUnloaded();
    });
  }

  setFiltersToUnloaded() {
    if (this.filters) {
      this.filters.Loaded = false;
    }
  }

  private initializeFilters(pageType: PageTypeEnum, id?: number) {
    let title: string | undefined;
    let contentType: string | undefined;
    if (pageType === PageTypeEnum.PsArticleLibrary) {
      title = this.translate.instant('ContentType.Articles');
      contentType = ContentTypeEnum.PsArticles;
    } else if (pageType === PageTypeEnum.PsAssignmentLibrary) {
      title = this.translate.instant('ContentType.Assignments');
      contentType = ContentTypeEnum.PsAssignments;
    } else if (pageType === PageTypeEnum.PsThemeLibrary) {
      contentType = ContentTypeEnum.PSThemes;
    }
    if (contentType) {
      this.filters = {
        PageType: pageType,
        ContentType: contentType,
        Title: title,
        Loaded: false,
        Loading: false,
        SearchBody: {
          Id: id,
          PageIndex: 1,
          SearchText: '',
          SelectedTopics: [],
          SelectedClasses: [],
          SelectedSubjects: [],
          OnlyFavorite: undefined,
        },
        Query: '',
        ShowSearch: pageType === PageTypeEnum.PsAssignmentLibrary,
        ShowSubjectsInHeader: pageType === PageTypeEnum.PsAssignmentLibrary,
        AssignmentsOpen: [],
        OnlyFavorite: {
          Checked: false,
          Title: this.translate.instant('PsLibrary.FilterOnlyFavoriteTitle'),
        },
        TotalFavoritesCount: 0,
      };
    }
  }

  async initialize(pageType: PageTypeEnum, id?: number) {
    this.initializeFilters(pageType, id);
    if (this.filters) {
      if (pageType === PageTypeEnum.PsAssignmentLibrary) {
        const assignmentsOpen = this.helperService.getQuerystringFacetList(QueryStrings.Open);

        if (assignmentsOpen.length) {
          this.filters.AssignmentsOpen = assignmentsOpen.map((item) => parseInt(item, 0));
        }
      }

      const urlQuery: string = this.helperService.getQueryParam(QueryStrings.Query) || '*';
      this.filters.Query = urlQuery !== '*' ? urlQuery : '';
      this.filters.SearchBody.SearchText = urlQuery;

      this.filters.SearchBody.OnlyFavorite = this.userService.isLoggedIn
        ? this.helperService.getQueryParam(QueryStrings.OnlyFavourite) === 'true'
        : false;
      this.filters.OnlyFavorite.Checked = this.filters.SearchBody.OnlyFavorite;

      const page = this.helperService.getQueryParam(QueryStrings.Page);
      if (page) {
        this.filters.SearchBody.PageIndex = this.helperService.tryParseInt(page, 1);
      }

      this.filters.SearchBody.SelectedTopics = this.helperService
        .getQuerystringFacetList(QueryStrings.Topics)
        .map((item) => parseInt(item, 0));
      this.filters.SearchBody.SelectedClasses = this.helperService
        .getQuerystringFacetList(QueryStrings.Classes)
        .map((item) => parseInt(item, 0));
      this.filters.SearchBody.SelectedSubjects = this.helperService
        .getQuerystringFacetList(QueryStrings.Subjects)
        .map((item) => parseInt(item, 0));

      await this.search();
    }
    return;
  }

  async clearQuery() {
    if (this.filters) {
      this.filters.Query = '';
      await this.search();
    }
  }

  async search(query?: string) {
    if (this.filters) {
      this.filters.SearchBody.SearchText = query ? query : this.filters.Query;
      const post = this.postSearch();
      if (post) {
        await firstValueFrom(
          post.pipe(
            catchError((error: HttpErrorResponse) => {
              this.pageService.showError(window.location.pathname, error.status);
              return EMPTY;
            }),
            tap((data) => {
              if (this.filters) {
                this.filters.TotalFavoritesCount = data.totalFavoritesCount;
                if (this.filters.PageType === PageTypeEnum.PsThemeLibrary) {
                  this.filters.Title = data.psTheme?.title;
                }
                this.filters.Data = this.psLibraryMapper.mapLibraryFromApi(data);
                if (this.filters.PageType === PageTypeEnum.PsAssignmentLibrary) {
                  this.filters.AssignmentsOpen.forEach((id) => {
                    const assignment = this.filters?.Data?.Assignments.find((item) => item.Id === id);
                    if (assignment) {
                      assignment.Open = true;
                    }
                  });
                }
                const subject = this.filters.Data.Subjects.find((item) => item.Selected === true);
                this.filters.SelectedSubjectBackgroundColor = subject?.BackgroundColor;
                this.filters.SelectedSubjectTextColor = subject?.TextColor;
                this.setQueryString(true);
                this.filters.Loaded = true;
                this.data.next(this.filters);
              }
            })
          )
        );
      }
    }
  }

  async resetFilters() {
    if (this.filters) {
      this.filters.SearchBody.SelectedTopics = [];
      this.filters.SearchBody.SelectedClasses = [];
      this.filters.SearchBody.SelectedSubjects = [];
      await this.search();
    }
  }

  postSearch() {
    if (this.filters) {
      const body = {
        id: this.filters.SearchBody.Id,
        pageIndex: this.filters.SearchBody.PageIndex,
        selectedTopics: this.filters.SearchBody.SelectedTopics,
        selectedSubjects: this.filters.SearchBody.SelectedSubjects,
        selectedClasses: this.filters.SearchBody.SelectedClasses,
        searchText: this.filters.SearchBody.SearchText,
        onlyFavorite: this.filters.SearchBody.OnlyFavorite,
      };
      if (this.filters.PageType === PageTypeEnum.PsAssignmentLibrary) {
        return this.apiService.postUmbraco<IUmbPsLibrary>(
          `alineaportal/psassignment/search/${this.appInitService.siteConfiguration.Id}`,
          body
        );
      } else if (this.filters.PageType === PageTypeEnum.PsArticleLibrary) {
        return this.apiService.postUmbraco<IUmbPsLibrary>(
          `alineaportal/psarticle/search/${this.appInitService.siteConfiguration.Id}`,
          body
        );
      } else if (this.filters.PageType === PageTypeEnum.PsThemeLibrary) {
        return this.apiService.postUmbraco<IUmbPsLibrary>(`alineaportal/pstheme/page/fetch/`, body);
      }
    }
    return;
  }

  setQueryString(replaceState?: boolean) {
    if (this.filters) {
      const link = this.helperService.getQueryParam(QueryStrings.Link);
      if (!link) {
        let params = new HttpParams();

        if (this.filters.SearchBody.SearchText !== '*' && this.filters.SearchBody.SearchText !== '') {
          params = params.append(QueryStrings.Query.en, this.filters.SearchBody.SearchText);
        }

        if (this.userService.isLoggedIn && this.filters.SearchBody.OnlyFavorite) {
          params = params.append(QueryStrings.OnlyFavourite.en, this.filters.SearchBody.OnlyFavorite.toString());
        }

        if (this.filters.SearchBody.SelectedTopics.length) {
          params = params.append(QueryStrings.Topics.en, this.filters.SearchBody.SelectedTopics.join(','));
        }

        if (this.filters.SearchBody.SelectedClasses.length) {
          params = params.append(QueryStrings.Classes.en, this.filters.SearchBody.SelectedClasses.join(','));
        }

        if (this.filters.SearchBody.SelectedSubjects.length) {
          params = params.append(QueryStrings.Subjects.en, this.filters.SearchBody.SelectedSubjects.join(','));
        }

        if (this.filters.SearchBody.PageIndex > 1) {
          params = params.append(QueryStrings.Page.en, this.filters.SearchBody.PageIndex.toString());
        }

        const assignmentsOpen = this.filters.Data?.Assignments.filter((item) => item.Open === true).map((item) => {
          return item.Id;
        });
        if (assignmentsOpen?.length) {
          params = params.append(QueryStrings.Open.en, assignmentsOpen.toString());
        }

        if (replaceState) {
          this.location.replaceState(window.location.pathname, params.toString());
        } else {
          this.location.go(window.location.pathname, params.toString());
        }
      } else {
        const params = new URLSearchParams(window.location.search);
        params.delete(QueryStrings.Link.en);
        this.location.replaceState(window.location.pathname, params.toString());
      }
    }
  }

  toggleFiltersOpen() {
    if (this.filters) {
      this.filters.FiltersOpen = !this.filters.FiltersOpen;
      this.gtmService.pushTagWithUserType(GTMEvent.filter_accordion, {
        setting: this.filters.FiltersOpen,
      });
    }
  }

  async toggleSubjectFilter(filter: ILibraryTag) {
    if (this.filters?.Data) {
      if (this.filters.PageType === PageTypeEnum.PsAssignmentLibrary) {
        this.filters.SearchBody.SelectedClasses = [];
        this.filters.SearchBody.SelectedTopics = [];
      }
      this.filters.Data.Subjects = this.filters.Data.Subjects.map((item) => {
        if (item.Id === filter.Id) {
          item.Selected = !item.Selected;
        } else {
          item.Selected = item.Id === filter.Id;
        }
        return item;
      });
      const elem = this.filters.Data.Subjects.find((item) => item.Id === filter.Id);
      if (elem) {
        if (elem.Selected) {
          this.filters.SearchBody.SelectedSubjects = [elem.Id];
          this.filters.SelectedSubjectBackgroundColor = elem.BackgroundColor;
          this.filters.SelectedSubjectTextColor = elem.TextColor;
        } else {
          this.filters.SearchBody.SelectedSubjects = [];
          this.filters.SelectedSubjectBackgroundColor = undefined;
          this.filters.SelectedSubjectTextColor = undefined;
        }
        this.filters.SearchBody.PageIndex = 1;
        await this.search();
        this.setQueryString();
      }
    }
  }

  async toggleFilter(filter: ILibraryTag) {
    if (this.filters) {
      let elem: ILibraryTag | undefined;
      if (filter.Type === PsLibraryFilterEnum.Topic) {
        elem = this.filters.Data?.Topics.find((item) => item.Id === filter.Id);
      } else if (filter.Type === PsLibraryFilterEnum.Class) {
        elem = this.filters.Data?.Classes.find((item) => item.Id === filter.Id);
      } else if (filter.Type === PsLibraryFilterEnum.Subject) {
        elem = this.filters.Data?.Subjects.find((item) => item.Id === filter.Id);
      }
      if (elem) {
        elem.Selected = !elem.Selected;
        if (elem.Selected) {
          if (filter.Type === PsLibraryFilterEnum.Topic) {
            this.filters.SearchBody.SelectedTopics.push(elem.Id);
          } else if (filter.Type === PsLibraryFilterEnum.Class) {
            this.filters.SearchBody.SelectedClasses.push(elem.Id);
          } else if (filter.Type === PsLibraryFilterEnum.Subject) {
            this.filters.SearchBody.SelectedSubjects.push(elem.Id);
          }
        } else {
          if (filter.Type === PsLibraryFilterEnum.Topic) {
            this.filters.SearchBody.SelectedTopics = this.filters.SearchBody.SelectedTopics.filter(
              (item) => item !== elem?.Id
            );
          } else if (filter.Type === PsLibraryFilterEnum.Class) {
            this.filters.SearchBody.SelectedClasses = this.filters.SearchBody.SelectedClasses.filter(
              (item) => item !== elem?.Id
            );
          } else if (filter.Type === PsLibraryFilterEnum.Subject) {
            this.filters.SearchBody.SelectedSubjects = this.filters.SearchBody.SelectedSubjects.filter(
              (item) => item !== elem?.Id
            );
          }
        }
        this.filters.SearchBody.PageIndex = 1;
        await this.search();
        this.setQueryString();
      }
    }
  }

  setAssignmentOpen(assignment: IPsAssignment) {
    if (this.filters) {
      if (assignment.Open) {
        this.filters.AssignmentsOpen.push(assignment.Id);
      } else {
        this.filters.AssignmentsOpen = this.filters.AssignmentsOpen.filter((item) => item !== assignment.Id);
      }
      this.setQueryString();
    }
  }

  async changePage(pageIndex: number) {
    if (this.filters?.Data) {
      this.filters.Data.Pagination.Index = pageIndex;
      this.filters.SearchBody.PageIndex = pageIndex;
      window.scroll(0, 0);
      await this.search();
    }
  }

  async onlyFavoriteChange(checked: boolean) {
    if (this.filters) {
      this.filters.SearchBody.OnlyFavorite = checked;
      await this.search();
    }
  }

  async setTotalFavoritesCount(count: number) {
    if (this.filters) {
      this.filters.TotalFavoritesCount = count;
      if (this.filters.OnlyFavorite && !count) {
        this.filters.OnlyFavorite.Checked = false;
        this.filters.SearchBody.OnlyFavorite = false;
        await this.search();
      }
    }
  }

  async assignmentTagClick(tag: ILibraryTag) {
    const url = await firstValueFrom(this.navbarService.psAssignmentLibraryUrl$);
    let params = new HttpParams();

    if (tag.Type === PsLibraryFilterEnum.Level) {
      params = params.append(QueryStrings.Classes.en, tag.Id);
    } else if (tag.Type === PsLibraryFilterEnum.Topic) {
      params = params.append(QueryStrings.Topics.en, tag.Id);
    }

    params = params.append(QueryStrings.Link.en, true);

    await this.router.navigateByUrl(`${url}?${params.toString()}`, {
      skipLocationChange: false,
    });
  }

  async articleTagClick(tag: ILibraryTag) {
    const url = await firstValueFrom(this.navbarService.psArticleLibraryUrl$);

    let params = new HttpParams();

    if (tag.Type === PsLibraryFilterEnum.Topic) {
      params = params.append(QueryStrings.Topics.en, tag.Id);
    }
    params = params.append(QueryStrings.Link.en, true);

    await this.router.navigateByUrl(`${url}?${params.toString()}`, {
      skipLocationChange: false,
    });
  }
}
