import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageTypeEnum } from './enums/page-type.enum';
import { RouteHasPage } from './guards/route-has-page.guard';
import { SetGTMForRoute } from './guards/set-gtm-for-route.guard';
import { SetPageForRoute } from './guards/set-page-for-route.guard';
const routes: Routes = [
  // ps related
  {
    path: PageTypeEnum.PsArticle,
    loadChildren: () =>
      import('@portal-app/modules/routes/ps-article/ps-article.module').then((module) => module.PsArticleModule),
    data: { pageType: PageTypeEnum.PsArticle },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.PsAssignment,
    loadChildren: () =>
      import('@portal-app/modules/routes/ps-assignment/ps-assignment.module').then(
        (module) => module.PsAssignmentModule
      ),
    data: { pageType: PageTypeEnum.PsAssignment },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.PsArticleLibrary,
    loadChildren: () =>
      import('@portal-app/modules/routes/ps-library/ps-library.module').then((module) => module.PsLibraryModule),
    data: { pageType: PageTypeEnum.PsArticleLibrary },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.PsAssignmentLibrary,
    loadChildren: () =>
      import('@portal-app/modules/routes/ps-library/ps-library.module').then((module) => module.PsLibraryModule),
    data: { pageType: PageTypeEnum.PsAssignmentLibrary },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.PsThemeLibrary,
    loadChildren: () =>
      import('@portal-app/modules/routes/ps-library/ps-library.module').then((module) => module.PsLibraryModule),
    data: { pageType: PageTypeEnum.PsThemeLibrary },
    canActivate: [RouteHasPage],
  },
  // book related
  {
    path: PageTypeEnum.BookInfo + '/:id',
    loadChildren: () =>
      import('@portal-app/modules/routes/book-info/book-info.module').then((module) => module.BookInfoModule),
    data: { pageType: PageTypeEnum.BookInfo },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.BookTag + '/:tag/:id',
    loadChildren: () =>
      import('@portal-app/modules/routes/book-tag/book-tag.module').then((module) => module.BookTagModule),
    data: { pageType: PageTypeEnum.BookTag },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.BookTag + '/:tag/:id/:title',
    loadChildren: () =>
      import('@portal-app/modules/routes/book-tag/book-tag.module').then((module) => module.BookTagModule),
    data: { pageType: PageTypeEnum.BookTag },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.Book + '/:id',
    loadChildren: () => import('@portal-app/modules/routes/book/book.module').then((module) => module.BookModule),
    data: { pageType: PageTypeEnum.Book },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.BookCollection,
    loadChildren: () =>
      import('@portal-app/modules/routes/book-collection/book-collection.module').then(
        (module) => module.BookCollectionModule
      ),
    data: { pageType: PageTypeEnum.BookCollection },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.BookFavorites,
    loadChildren: () =>
      import('@portal-app/modules/routes/book-favorites/book-favorites.module').then(
        (module) => module.BookFavoritesModule
      ),
    data: { pageType: PageTypeEnum.BookFavorites },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.BookLibrary,
    loadChildren: () =>
      import('@portal-app/modules/routes/book-library/book-library.module').then((module) => module.BookLibraryModule),
    data: { pageType: PageTypeEnum.BookLibrary },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.BookTeacherDashboard,
    loadChildren: () =>
      import('@portal-app/modules/routes/book-teacher-dashboard/book-teacher-dashboard.module').then(
        (module) => module.BookTeacherDashboardModule
      ),
    data: { pageType: PageTypeEnum.BookTeacherDashboard },
    canActivate: [RouteHasPage],
  },
  // course related
  {
    path: PageTypeEnum.CourseFavorites,
    loadChildren: () =>
      import('@portal-app/modules/routes/course-favorites/course-favorites.module').then(
        (module) => module.CourseFavoritesModule
      ),
    data: { pageType: PageTypeEnum.CourseFavorites },
    canActivate: [SetGTMForRoute],
  },
  {
    path: PageTypeEnum.CourseLibrary,
    loadChildren: () =>
      import('@portal-app/modules/routes/course-library/course-library.module').then(
        (module) => module.CourseLibraryModule
      ),
    data: { pageType: PageTypeEnum.CourseLibrary },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.CourseStudentAnswers,
    loadChildren: () =>
      import('@portal-app/modules/routes/course-student-answers/course-student-answers.module').then(
        (module) => module.CourseStudentAnswersModule
      ),
    data: { pageType: PageTypeEnum.CourseStudentAnswers },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.CourseAnnualPlan,
    loadChildren: () =>
      import('@portal-app/modules/routes/course-annual-plan/course-annual-plan.module').then(
        (module) => module.CourseAnnualPlanModule
      ),
    data: { pageType: PageTypeEnum.CourseAnnualPlan },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.CourseFrontpage + '/:id',
    loadChildren: () =>
      import('@portal-app/modules/routes/course-frontpage/course-frontpage.module').then(
        (module) => module.CourseFrontpageModule
      ),
    data: { pageType: PageTypeEnum.CourseFrontpage },
    canActivate: [SetGTMForRoute],
  },
  // lounge related
  {
    path: PageTypeEnum.LoungeTeamAdmin,
    loadChildren: () =>
      import('@portal-app/modules/routes/lounge-proxy/lounge-proxy.module').then((module) => module.LoungeProxyModule),
    data: { pageType: PageTypeEnum.LoungeTeamAdmin },
    canActivate: [RouteHasPage],
  },
  // general stuff
  {
    path: PageTypeEnum.ContentPage,
    loadChildren: () => import('@portal-app/modules/routes/page/page.module').then((module) => module.PageModule),
    data: { pageType: PageTypeEnum.ContentPage },
    canActivate: [RouteHasPage],
  },
  {
    path: PageTypeEnum.Error503,
    loadChildren: () => import('@portal-app/modules/routes/page/page.module').then((module) => module.PageModule),
    data: { pageType: PageTypeEnum.Error503 },
    canActivate: [RouteHasPage],
  },
  {
    path: '**',
    loadChildren: () => import('@portal-app/modules/routes/empty/empty.module').then((module) => module.EmptyModule),
    canActivate: [SetPageForRoute],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top', // Always start scroll 0 on route change
      enableTracing: false, // Debugging purpose only, enable to debug routing
    }),
  ],
  providers: [SetPageForRoute, RouteHasPage, SetGTMForRoute],
  exports: [RouterModule],
})
export class AppRoutingModule {}
