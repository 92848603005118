import { Component, Inject, Injector, Input } from '@angular/core';
import { GoogleTagManagerService, UserService } from '@lru/felib';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ContentTypeEnum } from '@portal-app/enums/content-type.enum';
import { GTMEvent } from '@portal-app/enums/gtm-event.enum';
import { SectionTypeEnum } from '@portal-app/enums/section-type.enum';
import {
  ToggleBookCollectionFavouriteAction,
  ToggleBookContinueFavouriteAction,
  ToggleBookFinishedFavouriteAction,
} from '@portal-app/modules/routes/book-teacher-dashboard/store/teacher-dashboard.actions';
import { ITeacherDashboardState } from '@portal-app/modules/routes/book-teacher-dashboard/store/teacher-dashboard.reducer';
import { ActivityFavoriteService } from '@portal-app/services/activity/activity-favorite.service';
import { BookFavoriteService } from '@portal-app/services/book/book-favorite.service';
import { CourseFavoriteApiService } from '@portal-app/services/course/course-favorite-api.service';
import { PsFavoriteService } from '@portal-app/services/ps/ps-favorite.service';

import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { BookCollectionFavoriteService } from '../../../../../services/book/book-collection-favorite.service';
import { PSLibraryService } from '../../../../../services/ps/ps-library.service';

@Component({
  selector: 'portal-favorite[isFavorite][type][id]',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.scss'],
})
export class FavoriteComponent {
  @Input() isFavorite?: boolean;
  @Input() type?: string;
  @Input() id?: string;
  @Input() fill = false;
  @Input() title?: string;
  @Input() subType?: SectionTypeEnum;
  @Input() className?: string;

  private apiLocked = false;
  private toastrOptions: Partial<IndividualConfig> = {
    toastClass: 'ngx-toastr favorite',
  };

  constructor(
    public userService: UserService,
    private courseFavoriteApiService: CourseFavoriteApiService,
    @Inject(Injector) private readonly injector: Injector,
    private bookFavoriteService: BookFavoriteService,
    private bookCollectionFavoriteService: BookCollectionFavoriteService,
    private psFavoriteService: PsFavoriteService,
    private store: Store<ITeacherDashboardState>,
    private translate: TranslateService,
    private psLibraryService: PSLibraryService,
    private activityFavoriteService: ActivityFavoriteService,
    private gtmService: GoogleTagManagerService
  ) {}

  private get toastr() {
    return this.injector.get(ToastrService);
  }

  async onFavoriteClick() {
    if (this.userService.isLoggedIn && !this.apiLocked) {
      this.apiLocked = true;

      this.gtmService.pushTagWithUserType(this.isFavorite ? GTMEvent.Favourite_remove : GTMEvent.Favourite_set, {
        id: this.id,
        title: this.title,
        type: this.type,
        subType: this.subType,
        favorite: !this.isFavorite,
      });

      switch (this.type) {
        case ContentTypeEnum.Texts:
          this.isFavorite ? await this.removeTextFavourite() : await this.addTextFavourite();
          break;
        case ContentTypeEnum.Assignments:
          this.isFavorite ? await this.removeTaskFavourite() : await this.addTaskFavourite();
          break;
        case ContentTypeEnum.Courses:
          this.isFavorite ? await this.removeCourseFavourite() : await this.addCourseFavourite();
          break;
        case ContentTypeEnum.Books:
          this.isFavorite ? await this.unmarkBookAsFavourite() : await this.markBookAsFavourite();
          break;
        case ContentTypeEnum.Collections:
          this.isFavorite ? await this.unmarkCollectionAsFavourite() : await this.markCollectionAsFavourite();
          break;

        case ContentTypeEnum.Activities:
          this.isFavorite ? await this.unmarkActivityAsFavourite() : await this.markActivityAsFavourite();
          break;

        case ContentTypeEnum.PsArticles:
        case ContentTypeEnum.PsAssignments:
          this.isFavorite ? await this.psDeleteFavourite() : await this.psAddFavourite();
      }
    }
  }

  private async markActivityAsFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.activityFavoriteService.markFavorite(this.id));

    this.setFavoriteAndUnlockApi();
    this.toastAdd('ContentType.TheActivity');
  }

  private async unmarkActivityAsFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.activityFavoriteService.removeFavorite(this.id));
    this.setFavoriteAndUnlockApi();
  }

  private async addTextFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.courseFavoriteApiService.addTextFavorite(this.id));

    this.setFavoriteAndUnlockApi();
    this.toastAdd('ContentType.TheText');
  }

  private async removeTextFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.courseFavoriteApiService.removeTextFavorite(this.id));
    this.setFavoriteAndUnlockApi();
  }

  private async removeTaskFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.courseFavoriteApiService.removeTaskFavorite(this.id));
    this.setFavoriteAndUnlockApi();
  }

  private async addTaskFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.courseFavoriteApiService.addTaskFavorite(this.id));

    this.setFavoriteAndUnlockApi();
    this.toastAdd('ContentType.TheTask');
  }

  private async removeCourseFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.courseFavoriteApiService.removeCourseFavorite(this.id));
    this.setFavoriteAndUnlockApi();
  }

  private async addCourseFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.courseFavoriteApiService.addCourseFavorite(this.id));

    this.setFavoriteAndUnlockApi();
    this.toastAdd('ContentType.TheCourse');
  }

  private async psAddFavourite() {
    if (!this.id) {
      return;
    }

    const data = await firstValueFrom(this.psFavoriteService.addFavorite(this.id));
    this.isFavorite = !this.isFavorite;
    await this.psLibraryService.setTotalFavoritesCount(data.totalFavoritesCount);

    this.apiLocked = false;
    let name: string;
    if (this.type === ContentTypeEnum.PsArticles) {
      name = this.translate.instant('ContentType.TheArticle');
    } else {
      name = this.translate.instant('ContentType.TheAssignment');
    }

    this.toastAdd(name);
  }

  private async psDeleteFavourite() {
    if (!this.id) {
      return;
    }

    const data = await firstValueFrom(this.psFavoriteService.deleteFavorite(this.id));
    this.isFavorite = !this.isFavorite;
    await this.psLibraryService.setTotalFavoritesCount(data.totalFavoritesCount);
    this.apiLocked = false;
  }

  private async markBookAsFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.bookFavoriteService.addBookToFavorites(this.id));
    if (this.subType && this.subType === SectionTypeEnum.FinishedBooks) {
      this.store.dispatch(
        ToggleBookFinishedFavouriteAction({
          data: !this.isFavorite,
          id: this.id,
        })
      );
    } else if (this.subType && this.subType === SectionTypeEnum.BookContinue) {
      this.store.dispatch(
        ToggleBookContinueFavouriteAction({
          data: !this.isFavorite,
          id: this.id,
        })
      );
    }

    this.setFavoriteAndUnlockApi();

    this.toastAdd('ContentType.TheBook');
  }

  private async unmarkBookAsFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.bookFavoriteService.deleteBookFromFavorites(this.id));

    if (this.subType && this.subType === SectionTypeEnum.FinishedBooks) {
      this.store.dispatch(
        ToggleBookFinishedFavouriteAction({
          data: !this.isFavorite,
          id: this.id,
        })
      );
    } else if (this.subType && this.subType === SectionTypeEnum.BookContinue) {
      this.store.dispatch(
        ToggleBookContinueFavouriteAction({
          data: !this.isFavorite,
          id: this.id,
        })
      );
    }

    this.setFavoriteAndUnlockApi();
  }

  private async markCollectionAsFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.bookCollectionFavoriteService.addCollectionToFavorites(this.id));
    this.store.dispatch(
      ToggleBookCollectionFavouriteAction({
        data: !this.isFavorite,
        id: this.id,
      })
    );

    this.setFavoriteAndUnlockApi();

    this.toastAdd('ContentType.TheBookCollection');
  }

  private async unmarkCollectionAsFavourite() {
    if (!this.id) {
      return;
    }

    await firstValueFrom(this.bookCollectionFavoriteService.deleteCollectionFromFavorites(this.id));
    this.store.dispatch(
      ToggleBookCollectionFavouriteAction({
        data: !this.isFavorite,
        id: this.id,
      })
    );

    this.setFavoriteAndUnlockApi();
  }

  private setFavoriteAndUnlockApi() {
    this.isFavorite = !this.isFavorite;
    this.apiLocked = false;
  }

  private toastAdd(translationKey: string) {
    this.toastr.info(
      this.translate.instant('Label.BlankAddedToFavorites', {
        name: this.translate.instant(translationKey),
      }),
      '',
      this.toastrOptions
    );
  }
}
