import { Injectable } from '@angular/core';
import { LinkTypeEnum } from '@portal-app/enums/link-type.enum';
import { PageTypeEnum } from '@portal-app/enums/page-type.enum';
import { INavbarItem } from '@portal-app/interfaces/project/navbar.interface';
import { IUmbNavbar, IUmbNavbarItem } from '@portal-app/interfaces/umbraco/umb-navbar.interface';
import { NavbarService } from '@portal-app/services/shared/navbar.service';
import { HelperService } from '../../services/shared/helper.service';

@Injectable({
  providedIn: 'root',
})
export class NavbarMapper {
  constructor(private navbarService: NavbarService, private helperService: HelperService) {}

  pushItemToArray(item: IUmbNavbarItem, items: INavbarItem[], array: INavbarItem[], dropdownIndex?: number) {
    if (!item.isActive) {
      // dont add items that are not active
      return;
    }

    const url = this.helperService.getCustomUrl(item.customUrl || item.url);

    if (item.pageType === PageTypeEnum.CourseLibrary || item.pageType === PageTypeEnum.BookLibrary) {
      this.navbarService.setLibrary(url);
    } else if (item.pageType === PageTypeEnum.CourseFavorites || item.pageType === PageTypeEnum.BookFavorites) {
      this.navbarService.setFavorites(url);
    } else if (item.pageType === PageTypeEnum.CourseStudentAnswers) {
      this.navbarService.setCourseStudentAnswers(url);
    } else if (item.pageType === PageTypeEnum.CourseAnnualPlan) {
      this.navbarService.setCourseAnnualPlan(url);
    } else if (item.pageType === PageTypeEnum.PsArticleLibrary) {
      this.navbarService.setPsArticleLibrary(url);
    } else if (item.pageType === PageTypeEnum.PsAssignmentLibrary) {
      this.navbarService.setPsAssignmentLibrary(url);
    }

    if (item.isHidden) {
      return;
    }

    if (item.type === LinkTypeEnum.External) {
      item.url = item.url;
    } else if (item.contentTypeAlias === PageTypeEnum.FrontPage) {
      item.url = '/';
    } else if (item.customUrl) {
      item.url = url;
    }

    array.push({
      Id: item.type === LinkTypeEnum.Dropdown && dropdownIndex !== null ? dropdownIndex : item.id,
      Items: items,
      Title: item.name,
      Type: item.type,
      Target: item.target,
      Url: item.url,
    });
  }

  mapNavbarFromApi(navbar: IUmbNavbar): INavbarItem[] {
    const result: INavbarItem[] = [];

    for (const row of navbar.rows) {
      for (const column of row.columns) {
        if (column.value) {
          const items: IUmbNavbarItem[] = [];
          column.value.items.forEach((item) => {
            items.push(item);
          });
          items.forEach((item, index) => {
            const navbarItems: INavbarItem[] = [];
            if (item.type === LinkTypeEnum.Dropdown) {
              for (const child of item.items) {
                this.pushItemToArray(child, [], navbarItems);
              }
            }
            this.pushItemToArray(item, navbarItems, result, index);
          });
        }
      }
    }

    return result;
  }

  isLinkActive(url: string) {
    return url === window.location.pathname;
  }

  isDropdownActive(urls: string[]) {
    return urls.find((elem) => elem === window.location.pathname) ? true : false;
  }
}
