import { Injectable } from '@angular/core';
import { MenuTypeEnum } from '@portal-app/enums/menu-type.enum';
import {
  ILibraryCategory,
  ILibraryCategoryTag,
  ISiteConfiguration,
  ISiteHeaderColors,
} from '@portal-app/interfaces/project/site-configuration.interface';
import {
  IUmbSiteConfiguration,
  IUmbSiteConfigurationSettings,
  IUmbSiteConfigurationSettingsFilterTag,
} from '@portal-app/interfaces/umbraco/umb-site-configuration.interface';

@Injectable({
  providedIn: 'root',
})
export class SiteConfigurationMapper {
  mapSiteConfigurationFromApi(siteConfiguration: IUmbSiteConfiguration): ISiteConfiguration {
    const result: ISiteConfiguration = {
      Id: siteConfiguration.id.toString(),
      BasePath: '',
      ISBNs: siteConfiguration.isbns || [],
      ProjectType: siteConfiguration.category,
      ProjectGroup: siteConfiguration.portalProducts[0].externalId,
      NavbarId: siteConfiguration.menus.find((x) => x.type === MenuTypeEnum.Primary && x.isActive === true)?.id,
      MegaMenuId: siteConfiguration.menus.find((x) => x.type === MenuTypeEnum.Mega && x.isActive === true)?.id,
      Title: siteConfiguration.name,
      PrimaryColor: siteConfiguration.primaryColor || '#ff0000',
      PrimaryTextColor: siteConfiguration.primaryTextColor || '#ffffff',
      SecondaryColor: siteConfiguration.secondaryColor || '#00ff00',
      SecondaryTextColor: siteConfiguration.secondaryTextColor || '#ffffff',
      SiteBackgroundColor: siteConfiguration.siteBackgroundColor || '#ffffff',
      SiteLinkColor: siteConfiguration.siteLinkColor || '#000000',
      SiteTextColor: siteConfiguration.siteTextColor || '#1b1b1b',
      PortalId: siteConfiguration.portalId,
      HeaderColors: this.getPortalHeaderColors(siteConfiguration),
      ErrorPages: siteConfiguration.errorpages.map((item) => {
        return {
          Id: item.id,
          StatusCode: item.statusCode.toString(),
        };
      }),
      GoogleVerificationId: siteConfiguration.googleSiteVerificationCode,
      GoogleTagManagerId: siteConfiguration.googleGTM,
      FrontpageId: siteConfiguration.frontpage ? siteConfiguration.frontpage.id : undefined,
      LibraryCategories: this.mapLibraryCategories(siteConfiguration.portalSettings),
      logo:
        siteConfiguration.logoImageUrl || siteConfiguration.mobileLogoImageUrl
          ? {
              mobile: siteConfiguration.mobileLogoImageUrl ?? undefined,
              default: siteConfiguration.logoImageUrl ?? undefined,
            }
          : undefined,
      language: siteConfiguration.languageIsoCode,
    };
    // result.ISBNs.push("9788723907998"); //superreader
    // result.ISBNs.push("9788723526908"); //dansk
    // result.ISBNs.push("fagportalstartsider"); //fagportalstartsider
    return result;
  }

  mapLibraryCategories(settings: IUmbSiteConfigurationSettings): ILibraryCategory[] | undefined {
    if (!settings) {
      return;
    }
    const result: ILibraryCategory[] = [];
    for (const item of settings?.filtering?.filters) {
      const category: ILibraryCategory = {
        Text1: item.text1,
        Text2: item.text2,
        IconUrl: item.iconUrl,
        Emoji: item.emoji,
        BackgroundColorHex: item.backgroundColorHex,
        BookSubject: item.bookSubject ? this.mapLibraryCategoryTag(item.bookSubject) : undefined,
        BookTopic: item.bookTopic ? this.mapLibraryCategoryTag(item.bookTopic) : undefined,
        PortalLevels: item.portalLevels
          ? item.portalLevels.map((elem) => {
              return this.mapLibraryCategoryTag(elem);
            })
          : [],
      };
      result.push(category);
    }
    return result;
  }

  mapLibraryCategoryTag(item: IUmbSiteConfigurationSettingsFilterTag): ILibraryCategoryTag {
    const result: ILibraryCategoryTag = {
      Id: item.externalId,
      Title: item.title,
    };
    return result;
  }

  getPortalHeaderColors(siteConfiguration: IUmbSiteConfiguration): ISiteHeaderColors {
    const result: ISiteHeaderColors = {
      PrimaryBackgroundColor: '',
      PrimaryTextColor: '',
      SecondaryBackgroundColor: '',
      SecondaryTextColor: '',
    };

    if (siteConfiguration.headerPrimaryBackgroundColor) {
      result.PrimaryBackgroundColor = siteConfiguration.headerPrimaryBackgroundColor;
    } else {
      result.PrimaryBackgroundColor = siteConfiguration.primaryColor;
    }

    if (siteConfiguration.headerPrimaryTextColor) {
      result.PrimaryTextColor = siteConfiguration.headerPrimaryTextColor;
    } else {
      result.PrimaryTextColor = '#ffffff';
    }
    if (siteConfiguration.headerSecondaryBackgroundColor) {
      result.SecondaryBackgroundColor = siteConfiguration.headerSecondaryBackgroundColor;
    } else {
      if (siteConfiguration.headerPrimaryBackgroundColor) {
        result.SecondaryBackgroundColor = siteConfiguration.headerPrimaryBackgroundColor;
      } else {
        result.SecondaryBackgroundColor = siteConfiguration.primaryColor;
      }
    }

    if (siteConfiguration.headerSecondaryTextColor) {
      result.SecondaryTextColor = siteConfiguration.headerSecondaryTextColor;
    } else {
      if (siteConfiguration.headerPrimaryTextColor) {
        result.SecondaryTextColor = siteConfiguration.headerPrimaryTextColor;
      } else {
        result.SecondaryTextColor = '#ffffff';
      }
    }

    return result;
  }
}
