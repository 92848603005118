export enum PageTypeEnum {
  // umbraco related
  FrontPage = 'frontpage',
  ContentPage = 'contentpage',

  // course related
  CourseLibrary = 'course-library',
  CourseStudentAnswers = 'course-student-answers',
  CourseAnnualPlan = 'course-annual-plan',
  CourseFavorites = 'course-favorites',
  CourseFrontpage = 'course',

  // book related
  BookLibrary = 'book-library',
  BookFavorites = 'book-favorites',
  BookTeacherDashboard = 'book-teacher-dashboard',
  BookInfo = 'book-info',
  BookTag = 'book-tag',
  Book = 'book',
  BookCollection = 'book-collection',
  MyBookCollections = 'my-book-collections',
  BookCollectionAdd = 'book-collection-add',
  BookCollectionEdit = 'book-collection-edit',
  BookCollectionView = 'book-collection-view',

  // ps related
  PsArticle = 'ps-article',
  PsArticleLibrary = 'ps-article-library',
  PsAssignment = 'ps-assignment',
  PsAssignmentLibrary = 'ps-assignment-library',
  PsThemeLibrary = 'ps-theme-library',

  //lounge related
  LoungeTeamAdmin = 'lounge-team-admin',

  // internal
  Error = 'error',
  Error503 = 'error-503',
}

export enum CoursePageTypeEnum {
  Course = 'Course',
  Page = 'Page',
  Assignment = 'Assignment',
  Question = 'Question',
  Chapter = 'Chapter',
}
